<template>
  <img :src="theUrl" :class="theClass" v-if="theUrl">
  <img src="../assets/images/default.png" :class="theClass" v-else>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Image',
  props: ['imageName', 'width', 'height', 'theClass'],
  data () {
    return {
      theUrl: null
    }
  },
  watch: {
    async imageName () {
      if (this.imageName) {
        await this.getImageUrl()
      }
    }
  },
  async mounted () {
    await this.getImageUrl()
  },
  methods: {
    ...mapActions(['getImage']),
    async getImageUrl () {
      var width = 0
      var height = 0
      if (this.width > 0) {
        width = this.width
      } else if (this.height > 0) {
        height = this.height
      }
      const details = {
        imageName: this.imageName,
        width: width,
        height: height
      }
      const image = await this.getImage(details)
      if (image) {
        this.theUrl = image
      }
    }
  }
}
</script>
