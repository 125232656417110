<template>
  <div class="imageSlider" :style="{height: height + 'vh'}" @click.self="goTo">
    <div class="slider-inner" :style="{height: height + 'vh'}" @click="goTo">
      <div class="imageSlider-item" v-for="(poster, key) in posters" :key="key"  ref="theImage">
        <transition-group :name="transitionName">
          <Image :imageName="poster.mobileImageUrl" :width="theImageWidth" :height="theImageHeight" theClass="d-block w-100" :alt="poster.name" v-if="isMobile && key === slider" />
          <Image :imageName="poster.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="d-block w-100" :alt="poster.name" v-if="!isMobile && key === slider " />

          <!-- <img :src="url + '/static/uploads/images/' + poster.mobileImageUrl" class="d-block w-100" :alt="poster.name" v-if="isMobile && key === slider" />
          <img :src="url + '/static/uploads/images/' + poster.imageUrl" class="d-block w-100" :alt="poster.name" v-if="!isMobile && key === slider " /> -->
        </transition-group>
      </div>
    </div>
    <div class="carousel-control-prev cursor" @click="previous">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </div>
    <div class="carousel-control-next cursor" @click="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { defineAsyncComponent } from 'vue'
import Image from '@/components/Image.vue'

export default {
  name: 'Slider',
  components: {
    Image
  },
  props: ['height', 'posters'],
  data () {
    return {
      slider: 0,
      transitionName: 'slideFromRight',
      interval: null,
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  watch: {
    slider () {
      if (this.posters.length) {
        this.setActiveSlider(this.posters[this.slider])
      }
    },
    activeProject () {
      this.slider = this.posters.findIndex(poster => poster.project.id === this.activeProject.project.id)
    },
    isInteracted () {
      if (this.isInteracted) {
        this.stopInterval()
      }
    }
  },
  async mounted () {
    await this.getDimensions()
    if (this.posters) {
      this.setActiveSlider(this.posters[this.slider])
    }
    this.startInterval()
  },
  computed: {
    ...mapGetters([
      'activeProject',
      'isInteracted',
      'isMobile'
    ])
  },
  methods: {
    ...mapActions([
      'setActiveSlider'
    ]),
    goTo () {
      this.$router.push('/project/' + this.activeProject.project.id)
    },
    getDimensions () {
      if (this.$refs.theImage) {
        this.theImageWidth = this.$refs.theImage.clientWidth
        this.theImageHeight = this.$refs.theImage.clientHeight
      }
    },
    next () {
      this.transitionName = 'slideFromRight'
      if (this.slider === this.posters.length - 1) {
        this.slider = 0
      } else {
        this.slider = this.slider + 1
      }
    },
    previous () {
      this.transitionName = 'slideFromLeft'
      if (this.slider === 0) {
        this.slider = this.posters.length - 1
      } else {
        this.slider = this.slider - 1
      }
    },
    startInterval () {
      this.interval = setInterval(() => this.next(), 5000)
    },
    stopInterval () {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>
  .carousel-control-prev {
    z-index: 99;
  }
  .imageSlider-item {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .imageSlider-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .slideFromRight-enter-active {
    transition: all 0.5s ease;
  }
  .slideFromRight-leave-active {
    transition: all 0.5s ease;
  }
  .slideFromRight-enter-from {
    transform: translateX(100%);
    opacity: 1;
  }
  .slideFromRight-leave-to {
    transform: translateX(-100%);
    opacity: 1;
  }

  .slideFromLeft-enter-active {
    transition: all 0.5s ease;
  }
  .slideFromLeft-leave-active {
    transition: all 0.5s ease;
  }
  .slideFromLeft-enter-from {
    transform: translateX(-100%);
    opacity: 1;
  }
  .slideFromLeft-leave-to {
    transform: translateX(100%);
    opacity: 1;
  }
</style>
